<template>
  <div v-if="!initialLoading">
    <b-card :title="$t('admin.externalIntegrations.pageTitle')">
      <p>
        {{ $t('admin.externalIntegrations.pageDescription') }}
      </p>

      <div v-if="apiKey">
        <h5 class="mt-2">
          {{ $t('admin.externalIntegrations.apiKeySectionTitle') }}
        </h5>
        <div class="code-bg">
          <b-row align-h="between" align-v="center" class="mx-2 py-2">
            <span class="code-content">{{ apiKey }}</span>
            <b-button
              id="copy-btn"
              variant="outline-light"
              size="sm"
              v-clipboard:copy="apiKey"
              v-clipboard:success="onCopy"
            >
              <feather-icon icon="CopyIcon" />
            </b-button>
            <b-tooltip triggers="hover" placement="bottom" target="copy-btn">
              <b>{{
                isCopied
                  ? $t('admin.externalIntegrations.copiedTooltip')
                  : $t('admin.externalIntegrations.copyTooltip')
              }}</b>
            </b-tooltip>
          </b-row>
        </div>

        <b-row align-h="end" class="pr-1">
          <b-button
            @click="confirmRevokeModal = true"
            variant="danger"
            class="mt-2"
          >
            <b-spinner v-if="loading" small />
            <span v-else>{{
              $t('admin.externalIntegrations.revokeApiKeyBtn')
            }}</span>
          </b-button>
        </b-row>
      </div>

      <b-row v-else align-h="end" class="pr-1">
        <b-button @click="generateNewApiKey()" variant="primary" class="mt-2">
          <b-spinner v-if="loading" small />
          <span v-else>{{
            $t('admin.externalIntegrations.generateApiKeyBtn')
          }}</span>
        </b-button>
      </b-row>

      <div
        v-if="qrCodeLink"
        class="my-2"
        style="width: 100%; height: 1px; background-color: grey; opacity: 0.5"
      ></div>

      <b-row
        v-if="qrCodeLink"
        align-h="center"
        class="align-items-center justify-content-around"
      >
        <b-col cols="5" class="ml-4"
          ><span>{{
            $t('admin.externalIntegrations.QrCodeTooltip')
          }}</span></b-col
        >
        <b-col
          ><div class="qr-code-container">
            <QRCode :value="qrCodeLink" size="200" /></div
        ></b-col>

        <div class="code-bg mx-2 my-2">
          <b-row align-h="between" align-v="center" class="mx-2 py-2">
            <span class="code-content">{{ qrCodeLink }}</span>
            <b-button
              id="copy-btn"
              variant="outline-light"
              size="sm"
              v-clipboard:copy="qrCodeLink"
              v-clipboard:success="onCopy"
            >
              <feather-icon icon="CopyIcon" />
            </b-button>
            <b-tooltip triggers="hover" placement="bottom" target="copy-btn">
              <b>{{
                isCopied
                  ? $t('admin.externalIntegrations.copiedTooltip')
                  : $t('admin.externalIntegrations.copyTooltip')
              }}</b>
            </b-tooltip>
          </b-row>
        </div>


      </b-row>

      <b-row v-else align-h="end" class="pr-1">
        <b-button @click="generateQrCode()" variant="primary" class="mt-2">
          <span>{{ $t('admin.externalIntegrations.generateQrCode') }}</span>
        </b-button>
      </b-row>

      <b-row v-if="qrCodeLink" align-h="end" class="pr-1">
        <b-button @click="qrCodeLink = ''" variant="danger" class="mt-2">
          <span>{{ $t('admin.externalIntegrations.revokeQrCode') }}</span>
        </b-button>
      </b-row>

      <h5 class="my-2">
        {{ $t('admin.externalIntegrations.myDocumentsSectionTitle') }}
      </h5>
      <p>
        <strong>{{ $t('admin.externalIntegrations.measurementId') }}</strong>
        <span>{{
          $t('admin.externalIntegrations.measurementIdWhereToFind')
        }}</span>
      </p>
      <p>
        <strong>{{ $t('admin.externalIntegrations.parent') }}</strong>
        <span>{{ `${user.adminDocRef}` }}</span>
      </p>
      <p>
        <strong>{{ $t('admin.externalIntegrations.myEntity') }}</strong>
        <span>{{ `${user.adminDocRef}/measurements` }}</span>
      </p>
      <p v-if="user.role === RoleEnum.OPTIC_ADMIN">
        <strong>{{ $t('admin.externalIntegrations.mySellers') }}</strong>
        <span>{{
          `${user.adminDocRef}/sellers/{${$t(
            'admin.externalIntegrations.sellerId',
          )}\}/measurements`
        }}</span>
      </p>
      <p>
        <strong>{{ $t('admin.externalIntegrations.myTest') }}</strong>
        <a
          target="_blank"
          href="https://app.swaggerhub.com/apis/marcgc21/Lensxpert/1.1.0"
        >
          Lensxpert Swagger Open API</a
        >
      </p>

      <b-row align-h="between" class="mt-4">
        <b-col cols="10">
          <h5 class="mt-1">
            {{ $t('admin.externalIntegrations.getAllMeasurementsTitle') }}
          </h5>
        </b-col>
        <b-col>
          <b-button v-b-modal.modal-getAllMeasurements variant="flat-primary"
            >{{ $t('admin.externalIntegrations.modalTitle') }}</b-button
          >
        </b-col>
      </b-row>
      <b-modal
        id="modal-getAllMeasurements"
        centered
        :title="$t('admin.externalIntegrations.getAllMeasurementsTitle')"
        ok-only
        ok-title="Accept"
        hide-footer
        size="lg"
      >
        <b-embed
          type="iframe"
          aspect="16by9"
          :src="this.videosURL.getAllMeasurementsVideo"
          allowfullscreen="true"
        ></b-embed>
      </b-modal>

      <div class="code-bg">
        <b-row align-h="between" align-v="center" class="mx-2 py-2">
          <span class="code-content">{{ getAllMeasurementsCodeExample }}</span>
          <b-button
            id="copy-getall-btn"
            variant="outline-light"
            size="sm"
            v-clipboard:copy="getAllMeasurementsCodeExample"
            v-clipboard:success="onCopy"
          >
            <feather-icon icon="CopyIcon" />
          </b-button>
          <b-tooltip
            triggers="hover"
            placement="bottom"
            target="copy-getall-btn"
          >
            <b>{{
              isCopied
                ? $t('admin.externalIntegrations.copiedTooltip')
                : $t('admin.externalIntegrations.copyTooltip')
            }}</b>
          </b-tooltip>
        </b-row>
      </div>

      <b-row align-h="between" class="mt-1">
        <b-col cols="10">
          <h5 class="mt-1">
            {{ $t('admin.externalIntegrations.getMeasurementByIdTitle') }}
          </h5>
        </b-col>
        <b-col>
          <b-button v-b-modal.modal-getMeasurementById variant="flat-primary"
            >{{ $t('admin.externalIntegrations.modalTitle') }}</b-button
          >
        </b-col>
      </b-row>
      <b-modal
        id="modal-getMeasurementById"
        centered
        :title="$t('admin.externalIntegrations.getMeasurementByIdTitle')"
        ok-only
        ok-title="Accept"
        hide-footer
        size="lg"
      >
        <b-embed
          type="iframe"
          aspect="16by9"
          :src="this.videosURL.getMeasurementByIdVideo"
          allowfullscreen="true"
        ></b-embed>
      </b-modal>
      <div class="code-bg">
        <b-row align-h="between" align-v="center" class="mx-2 py-2">
          <span class="code-content">{{ getMeasurementByIdCodeExample }}</span>
          <b-button
            id="copy-getid-btn"
            variant="outline-light"
            size="sm"
            v-clipboard:copy="getMeasurementByIdCodeExample"
            v-clipboard:success="onCopy"
          >
            <feather-icon icon="CopyIcon" />
          </b-button>
          <b-tooltip
            triggers="hover"
            placement="bottom"
            target="copy-getid-btn"
          >
            <b>{{
              isCopied
                ? $t('admin.externalIntegrations.copiedTooltip')
                : $t('admin.externalIntegrations.copyTooltip')
            }}</b>
          </b-tooltip>
        </b-row>
      </div>

      <b-row align-h="between" class="mt-1">
        <b-col cols="10">
          <h5 class="mt-1">
            {{ $t('admin.externalIntegrations.createMeasurement') }}
          </h5>
        </b-col>
        <b-col>
          <b-button v-b-modal.modal-createMeasurement variant="flat-primary"
            >{{ $t('admin.externalIntegrations.modalTitle') }}</b-button
          >
        </b-col>
      </b-row>
      <b-modal
        id="modal-createMeasurement"
        centered
        :title="$t('admin.externalIntegrations.createMeasurement')"
        ok-only
        ok-title="Accept"
        hide-footer
        size="lg"
      >
        <b-embed
          type="iframe"
          aspect="16by9"
          :src="this.videosURL.createMeasurementVideo"
          allowfullscreen="true"
        ></b-embed>
      </b-modal>
      <div class="code-bg">
        <b-row align-h="between" align-v="center" class="mx-2 py-2">
          <span
            class="code-content"
            v-html="createMeasurementCodeExample"
          ></span>
          <b-button
            id="copy-create-btn"
            variant="outline-light"
            size="sm"
            v-clipboard:copy="createMeasurementCodeExample"
            v-clipboard:success="onCopy"
          >
            <feather-icon icon="CopyIcon" />
          </b-button>
          <b-tooltip
            triggers="hover"
            placement="bottom"
            target="copy-create-btn"
          >
            <b>{{
              isCopied
                ? $t('admin.external-integrations.copiedTooltip')
                : $t('admin.external-integrations.copyTooltip')
            }}</b>
          </b-tooltip>
        </b-row>
      </div>

      <b-row align-h="between" class="mt-1">
        <b-col cols="10">
          <h5 class="mt-1">
            {{ $t('admin.externalIntegrations.updateMeasurement') }}
          </h5>
        </b-col>
        <b-col>
          <b-button v-b-modal.modal-updateMeasurement variant="flat-primary"
            >{{ $t('admin.externalIntegrations.modalTitle') }}</b-button
          >
        </b-col>
      </b-row>
      <b-modal
        id="modal-updateMeasurement"
        centered
        :title="$t('admin.externalIntegrations.updateMeasurement')"
        ok-only
        ok-title="Accept"
        hide-footer
        size="lg"
      >
        <b-embed
          type="iframe"
          aspect="16by9"
          :src="this.videosURL.updateMeasurementVideo"
          allowfullscreen="true"
        ></b-embed>
      </b-modal>
      <div class="code-bg">
        <b-row align-h="between" align-v="center" class="mx-2 py-2">
          <span
            class="code-content"
            v-html="updateMeasurementCodeExample"
          ></span>
          <b-button
            id="copy-update-btn"
            variant="outline-light"
            size="sm"
            v-clipboard:copy="updateMeasurementCodeExample"
            v-clipboard:success="onCopy"
          >
            <feather-icon icon="CopyIcon" />
          </b-button>
          <b-tooltip
            triggers="hover"
            placement="bottom"
            target="copy-update-btn"
          >
            <b>{{
              isCopied
                ? $t('admin.external-integrations.copiedTooltip')
                : $t('admin.external-integrations.copyTooltip')
            }}</b>
          </b-tooltip>
        </b-row>
      </div>

      <h5 class="mt-2">
        {{ $t('admin.externalIntegrations.computeMeasurement') }}
      </h5>
      <div class="code-bg">
        <b-row align-h="between" align-v="center" class="mx-2 py-2">
          <span
            class="code-content"
            v-html="computeMeasurementCodeExample"
          ></span>
          <b-button
            id="copy-update-btn"
            variant="outline-light"
            size="sm"
            v-clipboard:copy="computeMeasurementCodeExample"
            v-clipboard:success="onCopy"
          >
            <feather-icon icon="CopyIcon" />
          </b-button>
          <b-tooltip
            triggers="hover"
            placement="bottom"
            target="copy-update-btn"
          >
            <b>{{
              isCopied
                ? $t('admin.external-integrations.copiedTooltip')
                : $t('admin.external-integrations.copyTooltip')
            }}</b>
          </b-tooltip>
        </b-row>
      </div>

      <b-row align-h="between" align-v="start" class="px-1 mr-1 mb-2">
        <b-col>
          <h6 class="mt-2">
            {{ $t('admin.externalIntegrations.measurementTypes') }}
          </h6>
          <ul>
            <li>dnp</li>
            <li>progressive</li>
            <li>card</li>
            <li>pantoscopic</li>
          </ul>
        </b-col>
        <b-col>
          <h6 class="mt-2">
            {{ $t('admin.externalIntegrations.measurementLang') }}
          </h6>
          <ul>
            <li>en</li>
            <li>es</li>
            <li>pt</li>
            <li>it</li>
            <li>de</li>
          </ul>
        </b-col>
        <b-col>
          <h6 class="mt-2">
            {{ $t('admin.externalIntegrations.measurementRequired') }}
          </h6>
          <ul>
            <li>
              <strong
                >{{ $t('admin.externalIntegrations.measurementRequiredAll') }}
              </strong>
              (api_key, type, parent)
            </li>
            <li>dnp (frontal_picture_file, ponte_size)</li>
            <li>progressive (frontal_picture_file, aro_size)</li>
            <li>card (card_picture_file)</li>
            <li>pantoscopic (side_picture_file)</li>
          </ul>
        </b-col>
        <b-col>
          <h6 class="mt-2">
            {{ $t('admin.externalIntegrations.measurementOptional') }}
          </h6>
          <ul>
            <li>language</li>
          </ul>
        </b-col>
      </b-row>
    </b-card>

    <AlertDialog
      id="revoke-api-key"
      :show="confirmRevokeModal"
      :message="$t('admin.externalIntegrations.revokeModalMsg')"
      class="red--text"
      :title="$t('admin.externalIntegrations.revokeModalTitle')"
      @action="
        revokeCurrentApiKey()
        confirmRevokeModal = false
      "
      @close="confirmRevokeModal = false"
    />
  </div>
  <Loader v-else />
</template>

<script>
import { videosURL } from "@/utils/external-url"

import { BCard, BButton, BRow, BTooltip, BSpinner, BCol, BEmbed } from 'bootstrap-vue'
import QRCode from 'qrcode.vue'

import '@/libs/clipboard'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import AlertDialog from '@/components/AlertDialog.vue'
import ExternalIntegrationsController from '@/controllers/external-integrations.controller'

import Loader from '@/components/Loader.vue'

import RoleEnum from '@/utils/roles'

const eC = new ExternalIntegrationsController()
const baseURL = process.env.VUE_APP_LENSXPERT_API_CLOUD_ENDPOINT

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    BTooltip,
    BSpinner,
    QRCode,
    AlertDialog,
    Loader,
    BEmbed
  },
  data() {
    return {
      RoleEnum,
      apiKey: null,
      confirmRevokeModal: false,
      isCopied: false,
      loading: false,
      initialLoading: true,
      apiKeyDocId: null,
      qrCodeLink: '',
      videosURL: videosURL,
      getMeasurementByIdCodeExample: `CURL "${baseURL}/getMeasurementById?p={${this.$i18n.t(
        'admin.externalIntegrations.document',
      )}}&apiKey={API_KEY}"`,
      getAllMeasurementsCodeExample: `CURL "${baseURL}/getMeasurements?p={${this.$i18n.t(
        'admin.externalIntegrations.document',
      )}}&apiKey={API_KEY}&perPage=10&currentPage=1"`,
      createMeasurementCodeExample: `CURL --location
      --request POST '${baseURL}/createMeasurement' \
      <br /> <br /> --form 'frontal_picture_file=@"{FRONTAL_PICTURE_FILE}"' \
      <br /> --form 'side_picture_file=@"{SIDE_PICTURE_FILE}"' \
      <br /> --form 'card_picture_file=@"{CARD_PICTURE_FILE}"' \
      <br /> --form 'aro_size="{ARO_SIZE}"' \
      <br /> --form 'ponte_size="{PONTE_SIZE}"' \
      <br /> --form 'parent="{PARENT}"' \
      <br /> --form 'api_key="{API_KEY}"' \
      <br /> --form 'language="{LANGUAGE}"' \
      <br /> --form 'type="{TYPE}"' \
      <br /> --form 'url_return="{JSON
      }"'`,
      updateMeasurementCodeExample: `CURL --location --request PATCH '${baseURL}/updateMeasurement' \
      <br /> <br /> --form 'frontal_picture_file=@"{FRONTAL_PICTURE_FILE}"' \
      <br /> --form 'side_picture_file=@"{SIDE_PICTURE_FILE}"' \
      <br /> --form 'card_picture_file=@"{CARD_PICTURE_FILE}"' \
      <br /> --form 'aro_size="{ARO_SIZE}"' \
      <br /> --form 'ponte_size="{PONTE_SIZE}"' \
      <br /> --form 'parent="{PARENT}"' \
      <br /> --form 'api_key="{API_KEY}"' \
      <br /> --form 'type="{TYPE}"' \
      <br /> --form 'language="{LANGUAGE}"' \
      <br /> --form 'id="{MEASUREMENT_ID}"'`,
      computeMeasurementCodeExample: `CURL --location --request POST '${baseURL}/computeMeasurement' \
      --header 'Content-Type: application/json' \
      --data-raw '{
    <br /> <br /> "data": {
        "cursors": {
            ...
        },
        "measurements": {
            "complete": [
                ...
            ]
        },
        "resizedImage": {
            "diffY": 0,
            "height": 0,
            "diffX": 0,
            "top": 0,
            "width": 0,
            "left": 0
        },
        "cssStyleImageContainer": {
            "brightness": 0,
            "position": {
                "u": "%",
                "x": 50,
                "y": 50
            },
            "size": {
                "sx": 50,
                "u": "%"
            }
        }
    }
}'`,
    }
  },
  computed: {
    user() {
      console.log(this.$store.state.auth.user)
      return this.$store.state.auth.user
    },
    userAdminData() {
      return this.$store.state.auth.userAdminDoc
    },
  },
  methods: {
    generateQrCode() {
      this.qrCodeLink = `${process.env.VUE_APP_MEASURER_TOOL_URL}/#/camera-externa?p=${this.user.adminDocRef}&logo=${this.userAdminData.logo}&lang=${this.$i18n.locale}&create-measurement=true&askName=true&serviceOrder=QR`
    },
    async generateNewApiKey() {
      this.loading = true
      const { data } = await eC.generateApiKey({
        userId: this.user.id,
      })

      this.apiKey = data
      this.loading = false
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$i18n.t(
            'admin.externalIntegrations.toastSuccessCreateMsg',
          ),
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    },
    async revokeCurrentApiKey() {
      this.loading = true
      await eC.revokeApiKey({ docId: this.apiKeyDocId, userId: this.user.id })
      this.apiKey = null
      this.apiKeyDocId = null
      this.loading = false
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$i18n.t(
            'admin.externalIntegrations.toastSuccessRevokeMsg',
          ),
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    },
    onCopy() {
      this.isCopied = true

      setTimeout(() => {
        this.isCopied = false
      }, 5000)
    },
  },
  async mounted() {
    const res = await eC.getMyApiKey({ docId: this.user.apiKeyId || null })

    if (res.data) {
      this.apiKey = res.data.apiKey
      this.apiKeyDocId = res.data.id
    } else this.apiKey = null

    this.initialLoading = false
  },
}
</script>

<style scoped>
.code-bg {
  background-color: black;
  border-radius: 10px;
}

.code-content {
  color: white;
  font-weight: bold;
  letter-spacing: 1px;
}

.qr-code-container {
  padding: 1.25rem;
  max-width: fit-content;
  background-color: white;
  border-radius: 0.625rem;
  border: 2px dashed grey;
}
</style>