import { callHttpsCallableFunction } from '../api/firebase'

import { cF } from '../utils/cloud-functions'

/**
 * Class that keeps all of the external integrations request logic.
 */
export default class ExternalIntegrationsController {
  /**
   * Generates an API key according to the given user id.
   *
   * @param {{userId: string}} data an object containing the user id.
   * @returns an object containing the api key.
   */
  async generateApiKey(data) {
    return callHttpsCallableFunction(cF.generateApiKey, data)
  }

  /**
   * Gets an API key according to the given document id.
   *
   * @param {{docId?: string}} data an object containing the document id.
   * @returns the API key.
   */
  async getMyApiKey(data) {
    return callHttpsCallableFunction(cF.getMyApiKey, data)
  }

  /**
   * Revokes/deletes an API key from some user.
   *
   * @param {{ docId: string, userId: string }} data an object containing the document user ids.
   */
  async revokeApiKey(data) {
    return callHttpsCallableFunction(cF.revokeApiKey, data)
  }
}
